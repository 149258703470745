/* eslint-disable react/prop-types */
import {
    Tooltip,
    Box,
    Button,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Typography,
    Stack
} from "@mui/material";
import {
    Add,
    Delete,
    MoreVert,
    Edit,
    ArrowForwardIos,
    DriveFileRenameOutline
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import {
    useParams,
    matchPath,
    useHistory,
    useLocation
} from "react-router-dom";
import stores from "stores";
import AssetModal from "./AssetModal";
import GroupModal from "./GroupModal";
import { portfolioIsTransactionsBased } from "api/constants";
import { PORTFOLIO } from "routes/privateRoutes";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FolderIcon from "@mui/icons-material/Folder";
import BenchmarkEditModal from "./EditBenchmark";

export const GroupMenu = (props) => {
    const params = useParams();
    const { portfolios } = stores;
    const [addAssetModalIsOpen, setNewAssetModalIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [renameGroupModalIsOpen, setRenameGroupModalIsOpen] = useState(false);
    const [groupName, setGroupName] = useState(props.data.id);
    const [editBenchmarkModalIsOpen, setEditBenchmarkModalIsOpen] =
        useState(false);

    useEffect(() => {
        setGroupName(props.data.id);
    }, [props.data.id]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => setAnchorEl(null);
    const handleOpenRenameGroupModal = () => {
        setRenameGroupModalIsOpen(!renameGroupModalIsOpen);
        handleClose();
    };
    const handleChange = (e) => setGroupName(e.target.value);

    const handleRenameGroup = () => {
        portfolios.renameGroup(
            {
                customerId: params.customerId,
                portfolioId: params.portfolioId,
                id: props.data.id,
                groupName
            },
            () => {
                handleOpenRenameGroupModal;
            }
        );
    };

    const handleOpenAddAssetModal = () => {
        setNewAssetModalIsOpen(true);
        handleClose();
    };

    const handleDeleteGroup = () => {
        portfolios.deleteGroup(
            params.customerId,
            params.portfolioId,
            props.data.id
        );
        handleClose();
    };

    const handleCloseAddAssetModal = () => {
        setNewAssetModalIsOpen(false);
    };

    const handleOpenEditBenchmarkModal = () => {
        setEditBenchmarkModalIsOpen(true);
        handleClose();
    };

    const handleCloseEditBenchmarkModal = () => {
        setEditBenchmarkModalIsOpen(false);
    };

    return (
        <Stack direction={"row"} spacing={2}>
            <BenchmarkEditModal
                open={editBenchmarkModalIsOpen}
                handleClose={handleCloseEditBenchmarkModal}
                customerId={params.customerId}
                portfolioId={params.portfolioId}
                data={props.data}
            />
            {portfolios.portfolioOverview && !portfolioIsTransactionsBased(portfolios.portfolioOverview.PortfolioType) && (
                    <>

                        <Tooltip title="Open menu">
                            <IconButton
                                aria-haspopup="true"
                                onClick={handleClick}
                                size="small"
                            >
                                <MoreVert fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleOpenAddAssetModal}>
                                <ListItemIcon>
                                    <Add fontSize="small" color="success" />
                                </ListItemIcon>
                                <Typography variant="body1">
                                    Add asset
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                onClick={
                                    handleOpenEditBenchmarkModal
                                }
                            >
                                <ListItemIcon>
                                    <Edit fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="body1">
                                    Edit benchmark
                                </Typography>
                            </MenuItem>
                            <MenuItem onClick={handleOpenRenameGroupModal}>
                                <ListItemIcon>
                                    <DriveFileRenameOutline
                                        fontSize="small"
                                        color="info"
                                    />
                                </ListItemIcon>
                                <Typography variant="body1">
                                    Rename group
                                </Typography>
                            </MenuItem>
                            <MenuItem onClick={handleDeleteGroup}>
                                <ListItemIcon>
                                    <Delete fontSize="small" color="error" />
                                </ListItemIcon>
                                <Typography variant="body1">
                                    Delete group
                                </Typography>
                            </MenuItem>
                        </Menu>
                        <GroupModal
                            open={renameGroupModalIsOpen}
                            handleClose={handleOpenRenameGroupModal}
                            onClickAction={handleRenameGroup}
                            value={groupName}
                            onChangeValue={handleChange}
                            actionLabel="Rename group"
                        />
                        <AssetModal
                            open={addAssetModalIsOpen}
                            handleClose={handleCloseAddAssetModal}
                            actionLabel="Add asset"
                            groupName={groupName}
                        />
                    </>
                )}

                {portfolios.portfolioOverview && portfolioIsTransactionsBased(portfolios.portfolioOverview.PortfolioType) && (
                    <>
                        <Tooltip title="Open menu">
                            <IconButton
                                aria-haspopup="true"
                                onClick={handleClick}
                                size="small"
                            >
                                <MoreVert fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >

                            <MenuItem onClick={handleOpenEditBenchmarkModal}>
                                <ListItemIcon>
                                    <Edit fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="body1">
                                    Edit benchmark
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </>
                )}
        </Stack>
    );
};

export const AssetMenu = (props) => {
    const { portfolios } = stores;

    const location = useLocation();
    const history = useHistory();
    const [isMoveToGroupMenu, setMoveToGroupMenu] = useState(false);
    const { params } = matchPath(location.pathname, {
        path: PORTFOLIO
    });
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [editBenchmarkModalIsOpen, setEditBenchmarkModalIsOpen] =
        useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setMoveToGroupMenu(false);
    };

    const handleDeleteAsset = () => {
        portfolios.deleteAsset(
            params.customerId,
            params.portfolioId,
            props.data.AssetID
        );
        handleClose();
    };

    const notTransactionsBased = !portfolioIsTransactionsBased(portfolios.portfolioOverview.PortfolioType);

    const handleSetMoveToGroup = () => {
        setMoveToGroupMenu(true);
    };

    const handleMoveAssetToGroup = (newGroup) => () => {
        portfolios.moveAssetToGroup(
            params.customerId,
            params.portfolioId,
            props.data.id,
            newGroup
        );
    };
    const handleOpenEditBenchmarkModal = () => {
        setEditBenchmarkModalIsOpen(true);
        handleClose();
    };
    const handleCloseEditBenchmarkModal = () => {
        setEditBenchmarkModalIsOpen(false);
    };

    return (
        <>
            <Stack direction={"row"} spacing={2}>
                
                    
                {portfolios.portfolioOverview && notTransactionsBased && (
                    <>
                        <Tooltip title="Open menu">
                            <IconButton
                                aria-haspopup="true"
                                onClick={handleClick}
                                size="small"
                            >
                                <MoreVert fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {isMoveToGroupMenu ? (
                                <>
                                    <Box
                                        style={{
                                            display: "flex",
                                            padding: "12px 8px",
                                            alignItems: "center",
                                            justifyContent:
                                                "space-between"
                                        }}
                                    >
                                        <Typography variant="body1">
                                            Choose group
                                        </Typography>
                                        <Button
                                            onClick={() =>
                                                setMoveToGroupMenu(
                                                    false
                                                )
                                            }
                                        >
                                            Back
                                        </Button>
                                    </Box>
                                    {portfolios.selectedPortfolioGroups.map(
                                        (el) => {
                                            return (
                                                <MenuItem
                                                    key={el}
                                                    onClick={handleMoveAssetToGroup(
                                                        el
                                                    )}
                                                >
                                                    <ListItemIcon>
                                                        <FolderIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <Typography variant="body1">
                                                        {el}
                                                    </Typography>
                                                </MenuItem>
                                            );
                                        }
                                    )}
                                </>
                            ) : (
                                <>
                                    <MenuItem
                                        onClick={() =>
                                            props.apiRef.startRowEditMode(
                                                props.data
                                            )
                                        }
                                    >
                                        <ListItemIcon>
                                            <Edit fontSize="small" />
                                        </ListItemIcon>
                                        <Typography variant="body1">
                                            Edit asset
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={handleSetMoveToGroup}
                                    >
                                        <ListItemIcon>
                                            <ArrowForwardIcon fontSize="small" />
                                        </ListItemIcon>
                                        <Typography variant="body1">
                                            Move to group
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={
                                            handleOpenEditBenchmarkModal
                                        }
                                    >
                                        <ListItemIcon>
                                            <Edit fontSize="small" />
                                        </ListItemIcon>
                                        <Typography variant="body1">
                                            Edit benchmark
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() =>
                                            handleDeleteAsset()
                                        }
                                    >
                                        <ListItemIcon>
                                            <Delete fontSize="small" />
                                        </ListItemIcon>
                                        <Typography variant="body1">
                                            Delete asset
                                        </Typography>
                                    </MenuItem>
                                </>
                            )}
                        </Menu>
                    </>
                )}
                        
                {portfolios.portfolioOverview && portfolioIsTransactionsBased(portfolios.portfolioOverview.PortfolioType) && (
                    <>
                        <Tooltip title="Open menu">
                            <IconButton
                                aria-haspopup="true"
                                onClick={handleClick}
                                size="small"
                            >
                                <MoreVert fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >

                            <MenuItem onClick={handleOpenEditBenchmarkModal}>
                                <ListItemIcon>
                                    <Edit fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="body1">
                                    Edit benchmark
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </>
                )}
                <BenchmarkEditModal
                    single
                    open={editBenchmarkModalIsOpen}
                    handleClose={handleCloseEditBenchmarkModal}
                    customerId={params.customerId}
                    portfolioId={params.portfolioId}
                    data={props.data}
                />

                        
                            <IconButton
                                size="small"
                                onClick={() =>
                                    history.push(
                                        `/asset/${props.data.id}-${props.data.CurrencyCode}`
                                    )
                                }
                            >
                                <ArrowForwardIos fontSize="small" />
                            </IconButton>
                        
                    
            </Stack>
        </>
    );
};

export const NonCalculatedAssetMenu = (props) => {
    const history = useHistory();
    return (
        
        <IconButton size="small" onClick={() => {
            history.push(`/asset/${props.data.id}-${props.data.CurrencyCode}/prices`)
        }}><ArrowForwardIos fontSize="small" /></IconButton>
    
    )
}

export const RowMenu = (props) => {
    return (
        <>
            {props.data.variant === "group" ? (
                <GroupMenu data={props.data} apiRef={props.apiRef} />
            ) : props.data.variant === "asset" ? (
                <AssetMenu data={props.data} apiRef={props.apiRef} />
            ) : props.data.variant === "non-calculated-asset" ? (
                <NonCalculatedAssetMenu data={props.data} apiRef={props.apiRef} />
            ) : (
                <> </>
            )}
        </>
    );
};
