/* eslint-disable no-console */
import { API_URL, REQUEST_RESPONSE_CODES } from 'api/constants';
import axios from "axios";
import { LOGIN_PAGE_PATH } from 'routes/privateRoutes';
import { getToken, removeToken } from 'utils/localStorage';
import { APIS } from './constants';

const fixedToken = process.env.REACT_APP_FIXED_TOKEN

export const internalapiCall = async function(method, url, data) {
    const instance = () => {
        const options = {
            baseURL: API_URL,
            headers: {
                "Authorization": localStorage.getItem("TOKEN"),
                "Accept": "*/*",
                "Content-Type": "application/json"
            },
            withCredentials: true
        }

        return axios.create(options);
    }

    const request = instance();
    try {
        
        const response = await request({ method, url, data })
    
        if (response.status === 403) {
            removeToken();
            document.location.replace(LOGIN_PAGE_PATH);
        }
    
        return response;
    } catch (error) {
        if(error.response && error.response.status === 403) {
            removeToken();
            document.location.replace(LOGIN_PAGE_PATH);
        }
    }
}

async function call (url, parameters, method = "POST") {
    const instance = () => {
        let accessToken = getToken();

        if(url === APIS.GET_CF_TYPES_LISTS) {
            accessToken = fixedToken;
        }

        if (!accessToken && url !== APIS.LOGIN) {
            // localStorage.clear(); // Clear localStorage
            // TODO: Add to a function
            localStorage.removeItem("TOKEN");
            localStorage.removeItem("USER");
            localStorage.removeItem("customers");
            localStorage.removeItem("customersById");
            localStorage.removeItem("customers");
            localStorage.removeItem("sb-pokuxoinbklcuiorbeya-auth-token");
            document.location.replace(LOGIN_PAGE_PATH); // Redirect to login
            return; // Exit the function
        }
        const options = {
            baseURL: API_URL,
            headers: {
                'Access-Control-Allow-Origin': "*",
                "Accept": "*/*",
                "Content-Type": "application/json",
            }
        }
        
        if(url !== APIS.LOGIN) {
            options.headers.Token = accessToken
        }
        return axios.create(options);
    }

    

    const request = instance();
    const response = await request({ method, url, data: {...parameters} })
    
    const tokenIssue = 
        response.data.ErrorCode === REQUEST_RESPONSE_CODES.MISSING_TOKEN_IN_HEADER || 
        response.data.ErrorCode === REQUEST_RESPONSE_CODES.INVALID_TOKEN_ERROR_CODE || 
        response.data.ErrorCode === REQUEST_RESPONSE_CODES.MISSING_TOKEN ||
        response.data.ErrorCode === REQUEST_RESPONSE_CODES.LOGIN_HAS_EXPIRED_ERROR_CODE;
        if(tokenIssue) {
            removeToken();
            document.location.replace(LOGIN_PAGE_PATH);
        }

        if(response.status === 200 && (response.data.ErrorCode === REQUEST_RESPONSE_CODES.REQUEST_SUCCESS_CODE || response.data.ErrorCode === undefined)) {
            return { success: true, message: response.data, meta: response }
        }

        console.table([
            {"Failed request": response.config.url},
            { "params": JSON.stringify({...parameters}) },
            { "status": response.status },
            { "res data": response.data },
        ])
        return { success: false, message: response.data.ErrorText || "Unable to fetch", meta: response }
}

export default call