/* eslint-disable react/prop-types */
import { Stack, Button, FormControl, FormHelperText, Input, InputLabel } from '@mui/material';
import Modal from "Modal/Modal";
import React from 'react';

// TODO: inline edit columns
// https://reactdatagrid.io/docs/api-reference#props-columns-rendersInlineEditor

const GroupModal = (props) => {
    const handleRenderFooter = () => (
        <Stack direction="row" spacing={2}>
            <Button variant="text" onClick={props.onClickAction}>{props.actionLabel}</Button>
            <Button variant="contained" color="error" onClick={props.handleClose}>Cancel</Button>
        </Stack>
    )

    return (
        <Modal open={props.open} title={`Rename group`} handleClose={props.handleClose} renderFooter={handleRenderFooter}>
            <FormControl>
                <InputLabel htmlFor="component-helper">Group name</InputLabel>
                <Input
                    id="component-helper"
                    value={props.value}
                    onChange={props.onChangeValue}
                    aria-describedby="component-helper-text"
                />
                <FormHelperText id="component-helper-text">
                    The groupname must be unique
                </FormHelperText>
            </FormControl>
        </Modal>
    )
}


export default GroupModal