/* eslint-disable react/prop-types */
import { Box, Card, Typography } from '@mui/material';
import React from "react";
import { numberWithCommas } from "utils/numberManipulators";
import { isObject, isNumber } from "utils/typeChecker"

const CustomTooltip = (props) => {

    return (
        <Box component={Card} style={{ padding: "16px", display: "flex", flexDirection: "row", zIndex: "999999999", position: "relative" }}>
            <Box style={{ display: "flex", flexDirection: "column" }}>
                {props && props.payload && props.payload.length && (
                    <>
                        {props.headline && (
                            <Typography variant='body1'>{isObject(props.headline) ? (props.payload[0].payload[props.headline.key] || "undefined") : props.headline}</Typography>
                        )}
                        {props.payload.map((el, i) => {
                            // if(props.showSingleTooltip && i > 0) {
                            //     return <div key={`ignore_key-${i}`} />
                            // }
                            return (
                                <Box key={el.dataKey}>
                                    {props.keys.map((keyItem, i) => {
                                        const val = el.payload[keyItem.key === "@DATAKEY" ? el.dataKey : keyItem.key]
                                        const postFixVal = keyItem.postFix[0] === "@" ? el.payload[keyItem.postFix.substring(1)] : keyItem.postFix
                                        if(val !== undefined && val !== null) {
                                            return (
                                                <Typography sx={{ margin: 4, padding: 2, color: el.color || el.fill || el.stroke }} key={keyItem.key}>{keyItem.label === "@DATAKEY" ? el.dataKey : keyItem.label}: <span>{isNumber(val) ? numberWithCommas(val) : val}</span> <span>{postFixVal}</span></Typography>
                                            )
                                        }
                                        return (
                                            <div key={`missing_key_${i}`} />
                                        )
                                    })}
                                </Box>
                            )
                        })}
                    </>
                )}
            </Box>
        </Box>
    )
}


export default CustomTooltip
