/* eslint-disable react/prop-types */
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Box,
    Typography
} from "@mui/material";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import {
    CartesianGrid,
    LineChart,
    Legend,
    Line,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    ReferenceLine
} from "recharts";
import stores from "stores";
import CustomTooltip from "Charts/CustomTooltip";
import {
    benchmarkPeriods,
    benchmarkReturnTypes
} from "Pages/Benchmarks/constants";
import { formattedDateToString } from "utils/dateFormatters";
import { colors } from "styles/variables";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { useParams } from "react-router-dom";
import { numberWithCommas } from "utils/numberManipulators";

const ChartActions = observer((props) => {
    const { benchmarks } = stores;

    const handleSetPriceReturnTypeFilter = (e) =>
        benchmarks.setPriceReturnTypeFiler(e.target.value);
    const handleSetFromDateFilter = (e, d) =>
        benchmarks.setBenchmarkChartPeriodFilter(
            e.target.value,
            d.props.interval
        );

    const handleSelectComparedBenchmarks = (e) => {
        const params = e.target.value.map((assetClassShortName) => ({
            AssetClass_ShortName: assetClassShortName,
            Weight: 100
        }));

        benchmarks.fetchBenchmarkChartData(params);
    };

    return (
        <Box style={{ flexDirection: "flex-end" }}>
            <Stack direction={"row"} spacing={4}>
                <FormControl sx={{ width: 400 }}>
                    <InputLabel id="label-id-compared-benchmarks">
                        Compared Benchmarks
                    </InputLabel>
                    <Select
                        labelId="label-id-compared-benchmarks"
                        id="compared-benchmarks"
                        value={benchmarks.selectedBenchmarksKeys}
                        multiple
                        label="Compared Benchmarks"
                        onChange={handleSelectComparedBenchmarks}
                        size="small"
                    >
                        {benchmarks.benchmarksList.map((be) => {
                            return (
                                <MenuItem
                                    key={be.value}
                                    value={be.value}
                                    disabled={
                                        be.value === props.selectedBenchmark
                                    }
                                >
                                    {be.label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 140 }}>
                    <InputLabel id="label-id-period">Period</InputLabel>
                    <Select
                        labelId="label-id-period"
                        id="id-period"
                        value={benchmarks.benchmarkChartPeriodFilter}
                        label="Period"
                        onChange={handleSetFromDateFilter}
                        size="small"
                    >
                        {benchmarkPeriods.map((el) => {
                            return (
                                <MenuItem
                                    inerval={el.interval}
                                    selected={
                                        benchmarks.benchmarkChartPeriodFilter ===
                                        el.value
                                    }
                                    key={el.label}
                                    value={el.value}
                                >
                                    {el.label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 140 }}>
                    <InputLabel id="label-id-return-type">
                        Return Type
                    </InputLabel>
                    <Select
                        labelId="label-id-return-type"
                        id="id-return-type"
                        value={benchmarks.priceReturnTypeFilter}
                        label="Return Type"
                        onChange={handleSetPriceReturnTypeFilter}
                        size="small"
                    >
                        {benchmarkReturnTypes.map((el) => {
                            return (
                                <MenuItem
                                    selected={
                                        benchmarks.priceReturnTypeFilter ===
                                        el.value
                                    }
                                    key={el.value}
                                    value={el.value}
                                    disabled={
                                        el.value === "Raw" &&
                                        benchmarks.selectedBenchmarksKeys
                                            .length > 1
                                    }
                                >
                                    {el.label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Stack>
        </Box>
    );
});

const BenchmarkDetails = observer(({ benchmarkId }) => {
    const { benchmarks } = stores;
    const params = useParams();

    useEffect(() => {
        benchmarks.initializeBenchmarkDetails(benchmarkId);
    }, [benchmarks, benchmarkId]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%",
                paddingX: "15px"
            }}
        >
            <Typography variant="h5" sx={{ paddingLeft: "10px" }}>
                {benchmarks.benchmarkName}
            </Typography>

            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end"
                }}
            >
                <ChartActions selectedBenchmark={params.benchmarkId} />
            </Box>
            <Box
                sx={{
                    width: "100%",
                    height: "100%"
                }}
            >
                {Object.keys(benchmarks.benchmarkChartData).length > 0 && (
                    <ResponsiveContainer width="100%" height="90%">
                        <LineChart
                            margin={{
                                top: 20,
                                right: 20,
                                left: 20,
                                bottom: 20
                            }}
                        >
                            <Tooltip
                                content={
                                    <CustomTooltip
                                        headline={{ key: "date" }}
                                        keys={[
                                            {
                                                key: "@DATAKEY",
                                                postFix: "",
                                                label: "@DATAKEY"
                                            },
                                            {
                                                key: "return",
                                                postFix: "%",
                                                label: "Return"
                                            }
                                        ]}
                                    />
                                }
                            />
                            <Legend />
                            <CartesianGrid
                                stroke="#eee"
                                strokeDasharray="5 5"
                            />
                            <YAxis
                                tickFormatter={(value) => value.toFixed(2)}
                                domain={[
                                    benchmarks.benchmarkPriceRange.min - 1,
                                    benchmarks.benchmarkPriceRange.max + 1
                                ]}
                                yAxisId="vdfvdfvdf"
                                type="number"
                            />
                            <XAxis
                                dataKey="date"
                                xAxisId={
                                    Object.keys(
                                        benchmarks.benchmarkChartData
                                    )[0]
                                }
                                interval={benchmarks.benchmarkChartDataInterval}
                            />
                            {Object.keys(benchmarks.benchmarkChartData).map(
                                (key, i) => {
                                    return (
                                        <>
                                            <XAxis
                                                hide={true}
                                                dataKey="date"
                                                xAxisId={key}
                                                interval={
                                                    benchmarks.benchmarkChartDataInterval
                                                }
                                            />
                                            <Line
                                                xAxisId={key}
                                                yAxisId="vdfvdfvdf"
                                                data={
                                                    benchmarks
                                                        .benchmarkChartData[key]
                                                }
                                                type="monotone"
                                                dataKey={key}
                                                stroke={
                                                    colors.contrastPaletteArray[
                                                        i
                                                    ]
                                                }
                                                dot={null}
                                                activeDot={{ r: 8 }}
                                            />
                                        </>
                                    );
                                }
                            )}
                            <ReferenceLine
                                y={100}
                                label="Index 100"
                                stroke="red"
                                strokeDasharray="3 3"
                                xAxisId={
                                    Object.keys(
                                        benchmarks.benchmarkChartData
                                    )[0]
                                }
                                yAxisId="vdfvdfvdf"
                            />
                        </LineChart>
                    </ResponsiveContainer>
                )}
            </Box>
        </Box>
    );
});

export default BenchmarkDetails;
