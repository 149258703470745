import { action, makeObservable, observable, toJS, runInAction, extendObservable } from "mobx";
import { blankPage } from "./constants";
import pdfEditorSettingsStore from "../PDFEditorSettings/PDFEditorSettingsStore";
import portfolioWidgetsStore from "../portfolioWidgets/portfolioWidgetsStore";
import portfoliosStore from "../portfoliosStore";
import { pdfWidgetBaseData } from "stores/portfolioWidgets/constants";
import { internalapiCall } from "api/call";
import { IRISK_BO_API_URL } from "api/constants";
import snackbarStore from "stores/snackbarStore";
import { mergeArrays } from "utils/arrayHelpers";
import { widgetCategories, LARGE_PAPER, widgetTypes } from "stores/portfolioWidgets/constants";
import { widgetConstructor } from "stores/portfolioWidgets/utils";

class PDFEditorStore {
    report = null
    list = []
    hasInitializedReport = false;
    filteredReportPages = [];
    isConstructPortfolioPagesTriggered = false;

    constructor() {
        makeObservable(this, {
            list: observable,
            report: observable,
            filteredReportPages: observable,
            hasInitializedReport: observable,
            addPage: action,
            initialize: action,
            updateLayout: action,
            addWidgetToLayout: action,
            setReportPeriod: action,
            updatePageHeadline: action,
            saveDisclaimerText: action,
            getListOfReports: action,
            applySettingsToPages: action,
        })

        extendObservable(this, {
            processPages: action(function (rows, numOfPagesToCreate, numOfRowsWithinPage, holdingData) {
                let rowsStateCopy = [...rows]
    
                // Run the loop within an action to ensure it runs only once
                Array.from({ length: numOfPagesToCreate }).forEach((_, i) => {
                    const rowsForCurrentPage = rowsStateCopy.slice(0, numOfRowsWithinPage);
                    rowsStateCopy = rowsStateCopy.slice(numOfRowsWithinPage);
                    this.createHoldingPages(i, rowsForCurrentPage, holdingData); // Call action within action
                    this.applySettingsToPages()
                });
            })
        })
    }

    applySettingsToPages(newSettings = null) {
        let settings = this.report.settings;
        if(newSettings) {
            settings = newSettings;
        }
        let filteredPages = toJS(this.report.pagesList);
        const copyStatePagesList = toJS(this.report.pagesList)

        // First filter holding pages
        if(!settings.includeHolding) {
            filteredPages = copyStatePagesList.filter((pageId) => !pageId.includes("holding"))
        }
        this.filteredReportPages = filteredPages;

    }

    async getListOfReports() {
        // Get organisationID from organisationSettingsStore
        const organisationId = JSON.parse(localStorage.getItem("ORGANISATIONID"))
        // get UserId from userStore
        const userId = JSON.parse(localStorage.getItem("USER")).id

        try {
            const response = await internalapiCall("POST", `${IRISK_BO_API_URL}/report/pdf/list`, { userId, organisationId })
            if(response.status === 200) {
                runInAction(() => {
                    this.list = response.data.data
                })
            }
        } catch (error) {
            snackbarStore.handleOpen("Error fetching list of reports", "error")
        }
    }

    async saveDisclaimerText(disclaimerText, cb) {
        if(this.report && this.report._id) {
            const response = await internalapiCall("POST", `${IRISK_BO_API_URL}/report/pdf/update-disclaimer`, { reportId: this.report._id, disclaimerText })
            if(response.data.status === "Ok") {
                snackbarStore.handleOpen("Disclaimer updated", "success")
                if(cb) {
                    cb()
                }
            } else {
                snackbarStore.handleOpen("Error updating disclaimer", "error")
            }
        }
    }

    constructHoldings() {            
        const mergedBaseAndReturn = mergeArrays(portfoliosStore.selectedPortfolio, portfoliosStore.portfolioReturn.AllRows, "ID")
        const holdingData = {
            portfolio: {
                type: portfoliosStore.portfolioOverview.PortfolioType,
                currency: portfoliosStore.portfolioOverview.PortfolioCurrency,
                benchmark: [],
                groups: portfoliosStore.selectedPortfolioGroups,
                errors: portfoliosStore.selectedPortfolioErrors,
                information: portfoliosStore.portfolioOverview
            },
            rows: mergedBaseAndReturn,
            columnVisibilityModel: {
                name: true,
                Holding: true,
                AssetCleanPrice: true,
                Value: true,
                Return_In_Kroner: true,
            }
        }

        const numOfRowsWithinPage = 20
        const numOfPagesToCreate =  Math.ceil(mergedBaseAndReturn.length / numOfRowsWithinPage)

        if(numOfPagesToCreate > 1 && !this.isConstructPortfolioPagesTriggered) {
            store.processPages(mergedBaseAndReturn, numOfPagesToCreate, numOfRowsWithinPage, holdingData);    
            this.isConstructPortfolioPagesTriggered = true;
        } else {
            const widget = widgetConstructor("holding", "Holding", widgetCategories.holding, widgetTypes.holding)
            portfolioWidgetsStore.addDynamicWidgetAndData(widget, holdingData)
        }
    }

    async initialize(params) {
        this.hasInitializedReport = true;

        try {
            // Get organisationID from organisationSettingsStore
            const organisationId = JSON.parse(localStorage.getItem("ORGANISATIONID"))
            // get UserId from userStore
            const userId = JSON.parse(localStorage.getItem("USER")).id
    
            const URL = `${IRISK_BO_API_URL}/report/pdf/${organisationId}/${userId}`
            console.table(["START PDF INIT", URL, params])
    
            const pdfReport = await internalapiCall("GET", URL)
    
            if(!pdfReport || !pdfReport.data || !pdfReport.data.data) {
                console.log("NO REPORT FOUND")
                snackbarStore.handleOpen("No report found", "error")
                return;
            }
    
            runInAction(() => {
                this.report = pdfReport.data.data
                pdfEditorSettingsStore.initialize(this.report.settings);
            })
    
            if(params.customerId && params.portfolioId && portfoliosStore.fetchedId !== params.portfolioId) {
                console.log("FETCHING PORTFOLIO DETAILS")
                await portfoliosStore.fetchCustomerPortfolioDetails(params.customerId, params.portfolioId, this.report.settings.startDate, this.report.settings.endDate);

                this.constructHoldings()
            }
            // First we fetch calculations
            if(params.customerId && params.portfolioId && portfolioWidgetsStore.fetchedId !== params.portfolioId) {
                console.log("FETCHING DYNAMIC WIDGETS")
                await portfolioWidgetsStore.initializeHistoricalNoDataWidgets(params.customerId, params.portfolioId)
                portfolioWidgetsStore.setFetchedId(params.portfolioId)
            }
    
            runInAction(() => {
                const copyState = toJS(this.report)
                copyState.pagesList.forEach((pageId) => {
                    const page = copyState.pagesContent[pageId]
                    page.widgets = page.widgets.map((widget) => {
                        return {...widget, ...pdfWidgetBaseData }
                    })
                })
    
                if(this.report.pagesList.length === 0) {
                    this.addPage();
                }
    
                this.hasInitializedReport = false;
            });

        } catch (error) {
            snackbarStore.handleOpen("Error initializing PDF Editor", "error")
        }
        
    }

    updatePageHeadline(pageId, newHeadline) {
        this.report.pagesContent[pageId].headline = newHeadline;
    }

    async updateLayout(pageId, newLayout) {
        console.log("UPDATE LAYOUT", this.report)
        runInAction(() => {
            this.report.pagesContent[pageId].widgets = newLayout
        })

        if(this.report && this.report._id) {
            await internalapiCall("POST", `${IRISK_BO_API_URL}/report/pdf/update-layout`, { reportId: this.report._id, pageId: pageId, widgets: newLayout })
        }


        this.applySettingsToPages();
    }

    // setSelectedPageId(pageId) {
    //     this.selectedPageId = pageId;
    // }

    addWidgetToLayout(widget, pageId) {
        const widgetJS = toJS(widget)
        const widgetItem = { i: widgetJS.id, x: 0, y: Infinity, static: false, ...widgetJS.sizes.paper };

        this.report.pagesContent[pageId].widgets.push(widgetItem)
    }

    addPage(prevPageIndex = null, page = null) {
        if(this.report === null) {
            return 
        }

        if(page === null) {
            page = blankPage;
        }
        if(prevPageIndex !== null) {
            // add page to the right index in the pagesList'
            this.report.pagesList.splice(prevPageIndex + 1, 0, page.id);
        } else {
            this.report.pagesList.push(page.id);
        }
        const pageToAdd = page === null ? blankPage : page;
        this.report.pagesContent[page.id] = { ...pageToAdd, id: page.id };
        this.applySettingsToPages();
        return page.id;
    }

    createHoldingPages(pageIndex, rowsForCurrentPage, holdingData){
        if(!this.report) {
            return;
        }
        
        const programmedHoldingID = `holding_${pageIndex}`;
        // Create a new page
        const newHoldingPage = {
            id: programmedHoldingID,
            name: 'Holding',
            headline: 'Holding',
            displayHeaderBanner: true, // TODO: Should come from settings
            displayFooter: true, // TODO: Should come from settings
            widgets: []
        }
        const currentPageId = this.addPage(null, newHoldingPage);
        const currentPage = this.report.pagesContent[currentPageId];
    
        // Add to RGL
        currentPage.widgets.push({
            i: programmedHoldingID,
            x: 0,
            y: 0,
            w: LARGE_PAPER,
            h: Infinity,
            static: false
        });

        const holdingWidgetData = {
            ...holdingData,
            rows: rowsForCurrentPage,
        }

        const widget = widgetConstructor(programmedHoldingID, "Holding", widgetCategories.holding, widgetTypes.holding)
        portfolioWidgetsStore.addDynamicWidgetAndData(widget, holdingWidgetData)
    }

    setReportPeriod(period) {
        this.report.settings.period = period;
    }
}

const store = (window.pdfEditorStore = new PDFEditorStore());

export default store