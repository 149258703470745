/* eslint-disable react/prop-types */
import { Button, Grid, Box, TextField, Stack, FormControl, RadioGroup, FormControlLabel, FormLabel, Radio } from '@mui/material';
import { observer } from 'mobx-react';
import Modal from "Modal/Modal";
import React, { useState } from 'react';
import { useParams, matchPath, generatePath, useLocation } from 'react-router-dom';
import stores from "stores";
import SelectAsset from "./SelectAsset";
import { PORTFOLIO } from "routes/privateRoutes"

const AssetModal = observer((props) => {
    const { portfolios, data } = stores;
    const location = useLocation();
    const {params} = matchPath(location.pathname, {
        path: PORTFOLIO,
      });

    const [loading, setLoading] = useState(false);
    const [asset, setAsset] = useState({
        AssetName: "",
        AssetCurrency: "",
        AssetID: "",
        Holding: "",
        GroupName: props.groupName || "",
    });
    
    const handleSelectAsset = (item) => {
        const mapped = {
            AssetName: item.AssetName,
            AssetCurrency: item.CurrencyCode,
            AssetID: item.Isin,
            GroupName: props.groupName || "",
        }

       setAsset(mapped)
    }

    const handleAddAsset = () => {
        portfolios.addAsset(params.customerId, params.portfolioId, asset, props.handleClose)
    }

    const handleRenderFooter = () => (
        <Box>
            <Stack direction="row" spacing={2}>
                <Button variant="contained" color="success" onClick={handleAddAsset}>{props.actionLabel}</Button>
                <Button variant="text" color="error" onClick={props.handleClose}>Cancel</Button>
            </Stack>
        </Box>
    )

    const handleSearch = (newVal) => {
        setLoading(true);
        setTimeout(() => {
            portfolios.setSearchResults(newVal, () => {
                setLoading(false);
            });
        }, 2000)
    }

    const handleChangeAsset = (e, optionalId) => {
        setAsset(prevState => {
            let asset = {...prevState}  
            asset[optionalId] = e.target.value;
            return asset;                                
        })

    }

    return (
        <Modal open={props.open} title={`Add asset`} handleClose={props.handleClose} renderFooter={handleRenderFooter}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SelectAsset loading={loading} onSelect={handleSelectAsset} key="AssetName" onChange={(newValue) => handleSearch(newValue)} list={portfolios.searchResults} value={asset.AssetName} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="AssetName"
                        label="Asset name"
                        value={asset.AssetName}
                        onChange={(e) => handleChangeAsset(e, "AssetName")}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="AssetID"
                        label="Asset ISIN"
                        value={asset.AssetID}
                        onChange={(e) => handleChangeAsset(e, "AssetID")}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">Currency</FormLabel>
                    <RadioGroup
                        row
                        onChange={(e) => handleChangeAsset(e, "AssetCurrency")}
                        value={asset.AssetCurrency}
                        id="AssetCurrency"
                        name="row-radio-buttons-group"
                    >
                            {data.currencyCodes.map((currency) => {
                                return (
                                    <FormControlLabel key={`key__${currency}`} value={currency} control={<Radio />} label={currency} />
                                )
                            })}
                    </RadioGroup>
                </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        type="number"
                        id="Holding"
                        label="Holding"
                        value={asset.Holding}
                        onChange={(e) => handleChangeAsset(e, "Holding")}
                        variant="outlined"
                    />
                </Grid>
                {!props.groupName && (
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            type="text"
                            id="GroupName"
                            label="Group Name"
                            value={asset.GroupName}
                            onChange={(e) => handleChangeAsset(e, "GroupName")}
                            variant="outlined"
                        />
                    </Grid>
                )}
            </Grid>
        </Modal>
    )
})

export default AssetModal