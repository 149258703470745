import { useState, createContext, useEffect } from "react";

import { observer } from "mobx-react";
import stores from "stores";
import Modal from "Modal/Modal";
import {
    Stack,
    Box,
    FormControl,
    Grid,
    Button,
    TextField,
    Typography
} from "@mui/material";
import BenchmarksList from "./BenchmarksList";
import BenchmarkChart from "./BenchmarkChart";
import snackbarStore from 'stores/snackbarStore';

export const EditBenchmarkContext = createContext({
    single: false,
    selectedBenchmarks: [],
    setSelectedBenchmarks: () => {},
    selectedDisplayBenchmark: null,
    setSelectedDisplayBenchmark: () => {}
});

const BenchmarkEditModal = observer((props) => {
    const { portfolios } = stores;

    useEffect(() => {
        if(portfolios.benchmarks.length === 0 && props.open) {
            portfolios.fetchBenchmarks()
        } 
    }, [portfolios, props.open])

    const [selectedBenchmarks, setSelectedBenchmarks] = useState([]);
    const [selectedDisplayBenchmark, setSelectedDisplayBenchmark] =
        useState(null);
    const [step, setStep] = useState(0);

    const weightChangeHandler = (e, item) => {
        {
            let val = Number(e.target.value);

            val = Math.min(Math.max(val, 0), 100);

            setSelectedBenchmarks((addedBenchmarks) => {
                return addedBenchmarks.map((el) => {
                    return el.AssetClass_ShortName === item.AssetClass_ShortName
                        ? {
                              ...el,
                              weight: val
                          }
                        : el;
                });
            });
        }
    };

    const closeModal = () => {
        props.handleClose();
        setSelectedDisplayBenchmark(null);
        setSelectedBenchmarks([]);
        setStep(0);
    }
    
    const submitHandler = () => {
        if (selectedBenchmarks.length > 0) {
            const benchmarks = [];
            const weights = [];

            if (!props.single) {
                const notAllWeightsSet = selectedBenchmarks.some((el)=>{
                    return el.weight === undefined;
                })
    
                if (notAllWeightsSet) {
                    snackbarStore.handleOpen("Must set all weights", "error")
                    return;
                }
            }
        
            selectedBenchmarks.forEach((el) => {
                benchmarks.push(el.AssetClass_ShortName);
                weights.push(el.weight);
            });

            let payloadParams = {
                customerId: props.customerId,
                portfolioId: props.portfolioId,
                benchmarks: benchmarks,
                id: props.data.id
            }

            if(props.single) {
                payloadParams.weights = [100]
            } else {
                payloadParams.weights = weights
            }

            portfolios.updateAssignedBenchmarks(payloadParams, () => {
                closeModal();
            })
        }
    };

    return (
        <Modal
            title={`Edit benchmark - ${step === 0 ? "Select benchmark(s)" : "Set weight(s)"}`}
            handleClose={closeModal}
            open={props.open}
            fullWidth={true}
            maxWidth={step === 0 ? false : "md"}
        >
            {step === 0 && (
                <EditBenchmarkContext.Provider
                    value={{
                        single: props.single,
                        selectedDisplayBenchmark,
                        setSelectedDisplayBenchmark,
                        selectedBenchmarks,
                        setSelectedBenchmarks
                    }}
                >
                    <Stack
                        width={"100%"}
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            height: "70vh"
                        }}
                    >
                        <Box
                            sx={{
                                width: "320px"
                            }}
                        >
                            <BenchmarksList
                                handleOpenBenchmarkChart={
                                    setSelectedDisplayBenchmark
                                }
                            />
                        </Box>
                        <Box
                            sx={{
                                width: "60vw"
                            }}
                        >
                            {selectedDisplayBenchmark && (
                                <BenchmarkChart
                                    benchmarkId={
                                        selectedDisplayBenchmark.AssetClass_ShortName
                                    }
                                />
                            )}
                            {!selectedDisplayBenchmark && (
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                >
                                    <Typography variant="h5">
                                        Click on the chart icon near a
                                        benchmark to show the chart
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Stack>
                    <Grid
                        item
                        container
                        xs={12}
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <Grid item xs={1}>
                            <Button
                                disabled={selectedBenchmarks.length === 0}
                                onClick={() => {
                                    setStep(1);
                                }}
                                variant="contained"
                                color="success"
                                fullWidth
                            >
                                Continue
                            </Button>
                        </Grid>
                    </Grid>
                </EditBenchmarkContext.Provider>
            )}
            {step === 1 && (
                <Box width="100%">
                    <Stack spacing={2} sx={{
                        display: "flex",
                    }}>
                        {selectedBenchmarks &&
                            selectedBenchmarks.map((item, index) => {
                                return (
                                    <Stack key={index} sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        gap: "30px",
                                        borderBottom: index !== selectedBenchmarks.length - 1 ? "1px solid #f0f0f0" : "none",
                                        paddingBottom: "12px"
                                    }}>
                                        <Typography>
                                                {item.BenchMark_Name}
                                        </Typography>
                                        <Stack sx={{
                                            padding: 0
                                        }}>
                                            {props.single ? (
                                                <Typography>100</Typography>
                                            ) : (
                                                <FormControl fullWidth>
                                                    <TextField
                                                        label="Weight"
                                                        variant="outlined"
                                                        size="small"
                                                        type="number"
                                                        value={
                                                            item.weight ?? ""
                                                        }
                                                        onChange={(e) =>
                                                            weightChangeHandler(
                                                                e,
                                                                item
                                                            )
                                                        }
                                                    />
                                                </FormControl>
                                            )}
                                        </Stack>
                                    </Stack>
                                );
                            })}
                    </Stack>
                    <Grid
                        item
                        container
                        xs={12}
                        sx={{ display: "flex", justifyContent: "space-between", marginTop: "40px" }}
                    >
                        <Grid item xs={3}>
                            <Button
                                onClick={() => {
                                    setStep(0);
                                }}
                                variant="contained"
                                fullWidth
                            >
                                Back
                            </Button>
                        </Grid>
                        
                        <Grid item xs={3}>
                            <Button
                                disabled={props.single ? false : selectedBenchmarks.some((el)=>{
                                    return el.weight === undefined;
                                })}
                                onClick={submitHandler}
                                variant="contained"
                                color="success"
                                fullWidth
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </Modal>
    );
});

export default BenchmarkEditModal;
