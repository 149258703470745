/* eslint-disable react/prop-types */
import {
    Box,
    ListItem,
    ListItemText,
    List,
    IconButton,
    Checkbox,
    CircularProgress,
    TextField
} from "@mui/material";
import { useState, useRef, useContext } from "react";
import stores from "stores";
import { observer } from "mobx-react";
import { ShowChart } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { useVirtualizer } from "@tanstack/react-virtual";
import { EditBenchmarkContext } from ".";
import "./list.css";

const RowVirtualizerFixed = observer(({ rows }) => {
    const editBenchmark = useContext(EditBenchmarkContext);
    const parentRef = useRef(null);

    const rowVirtualizer = useVirtualizer({
        count: rows.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 50,
        overscan: 5
    });

    const handleDisplayBenchmark = (virtualRow) =>
        editBenchmark.setSelectedDisplayBenchmark(rows[virtualRow.index]);

    const handleSelectBenchmark = (virtualRow) => (e) => {
        const row = rows[virtualRow.index];

        const exists = editBenchmark.selectedBenchmarks.find((benchmark) => {
            return benchmark.AssetClass_ShortName === row.AssetClass_ShortName;
        });

        if (exists) {
            const filtered = editBenchmark.selectedBenchmarks.filter(
                (benchmark) => {
                    return (
                        benchmark.AssetClass_ShortName !==
                        row.AssetClass_ShortName
                    );
                }
            );

            editBenchmark.setSelectedBenchmarks(filtered);
        } else {
            if (editBenchmark.single) {
                editBenchmark.setSelectedBenchmarks([rows[virtualRow.index]]);
            } else {
                editBenchmark.setSelectedBenchmarks([
                    ...editBenchmark.selectedBenchmarks,
                    rows[virtualRow.index]
                ]);
            }
        }
    };

    return (
        <Box
            sx={{
                height: "100%",
                width: "100%"
            }}
        >
            <Box
                sx={{
                    position: "relative",
                    height: 0,
                    overflow: "visible",
                    top: "-8px"
                }}
            ></Box>
            <div
                ref={parentRef}
                className="List"
                style={{
                    height: `100%`,
                    width: `100%`,
                    overflow: "auto"
                }}
            >
                <List
                    style={{
                        height: `${rowVirtualizer.getTotalSize()}px`,
                        margin: "revert"
                    }}
                >
                    {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                        const selected =
                            editBenchmark.selectedDisplayBenchmark &&
                            editBenchmark.selectedDisplayBenchmark
                                .AssetClass_ShortName ===
                                rows[virtualRow.index].AssetClass_ShortName;

                        return (
                            <ListItem
                                key={virtualRow.index}
                                className={
                                    virtualRow.index % 2
                                        ? "ListItemOdd"
                                        : "ListItemEven"
                                }
                                style={{
                                    position: "absolute",
                                    marginTop: 0,
                                    padding: 0,
                                    top: 0,
                                    height: virtualRow.size,
                                    transform: `translateY(${virtualRow.start}px)`
                                }}
                            >
                                <IconButton
                                    disabled={selected}
                                    size="small"
                                    onClick={() => {
                                        handleDisplayBenchmark(virtualRow);
                                    }}
                                    sx={{
                                        padding: 0
                                    }}
                                >
                                    <ShowChart fontSize="small" />
                                </IconButton>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}
                                    className="ListItemInner"
                                    dense
                                    divider
                                    onClick={handleSelectBenchmark(virtualRow)}
                                >
                                    <Checkbox
                                        sx={{}}
                                        checked={editBenchmark.selectedBenchmarks.some(
                                            (el) => {
                                                return (
                                                    el.AssetClass_ShortName ===
                                                    rows[virtualRow.index]
                                                        .AssetClass_ShortName
                                                );
                                            }
                                        )}
                                    />
                                    <ListItemText
                                        primary={`${
                                            rows[virtualRow.index]
                                                .BenchMark_Name
                                        }`}
                                    />
                                </Box>
                            </ListItem>
                        );
                    })}
                </List>
            </div>
        </Box>
    );
});

const SideBarLoader = observer(() => {
    return (
        <Box
            sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <CircularProgress />
        </Box>
    );
});

const Sidebar = observer(({}) => {
    const { portfolios } = stores;
    const [search, setSearch] = useState("");
    const [searchedBenchmarks, setSearchedBenchmarks] = useState(
        portfolios.benchmarks
    );

    const handleSearch = (e) => {
        const searchString = e.target.value;
        setSearch(searchString);

        let newList = portfolios.benchmarks;

        newList = newList.filter(({ AssetClass_ShortName, BenchMark_Name }) => {
            return (
                AssetClass_ShortName.toLowerCase().includes(
                    searchString.toLowerCase()
                ) ||
                BenchMark_Name.toLowerCase().includes(
                    searchString.toLowerCase()
                )
            );
        });

        setSearchedBenchmarks(newList);
    };

    return (
        <Box sx={{ height: "100%" }}>
            <Stack spacing={2} direction={"row"}>
                <TextField
                    size="small"
                    label="Search benchmarks"
                    variant="outlined"
                    onChange={handleSearch}
                    value={search}
                    disabled={portfolios.benchmarks.length === 0}
                />
            </Stack>
            {portfolios.benchmarks.length === 0 ? (
                <SideBarLoader />
            ) : (
                <RowVirtualizerFixed rows={searchedBenchmarks} />
            )}
        </Box>
    );
});

export default Sidebar;
