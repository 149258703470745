import { makeAutoObservable } from "mobx";

class PortfolioReturnStore {
    AllRows = [];
    DisplayRows = [];
    columns = [];
    IsHolding = false;
    IsExpanded = false;
    FromDate = null;
    ToDate = null;
    earliestFromDate = null;
    latestFromDate = null;
    earliestToDate = null;
    latestToDate = null;
    NonIncludedAssets = [];
    ReturnTable = [];

    constructor() {
        makeAutoObservable(this);
    }
}

const store = (window.portfolioReturnStore = new PortfolioReturnStore());

export default store;

