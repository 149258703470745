/* eslint-disable react/prop-types */
import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Grid, TextField, Typography, Autocomplete } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { VariableSizeList } from 'react-window';
import ListSubheader from '@mui/material/ListSubheader';

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current !== null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Box key={dataSet[1].Isin} onClick={() => dataSet[2](dataSet[1])}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6">{dataSet[1].AssetName}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="caption">({dataSet[1].Isin})</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="caption">{dataSet[1].Ticker}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="caption">{dataSet[1].CurrencyCode}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

export default function Asynchronous(props) {
  const [open, setOpen] = React.useState(false);

  const handleSearch = (e) => {
    setOpen(true);
    props.onChange(e.target.value)
  }

  const handleSelect = (option) => {
    props.onSelect(option)
    setOpen(false);
  }

  return (
    <Autocomplete
      id="asynchronous-demo"
      fullWidth
      open={open}
      isOptionEqualToValue={(option, value) => {
        return option.AssetName === value.AssetName
      }}
      getOptionLabel={(option) => {
        return option.AssetName
      }}
      options={props.list || []}
      loading={props.loading}
      ListboxComponent={ListboxComponent}
      filterOptions={(x) => x}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            value={params.inputProps.value}
            onChange={handleSearch}
            placeholder={props.placeholder ?? "test"}
            label="Search Asset"
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )
      }}
      renderOption={(props, option) => [props, option, handleSelect]}
    />
  );
}